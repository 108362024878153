<template>
    <v-card hover class="ma-0 pa-0">
        <v-card-title style="background-color:#4E87DD; color:#FFFFFF; height:70px;">
            <v-row no-gutters>
                <v-col class="pt-1 pl-4 text-h5 font-weight-bold">이미지</v-col>
            </v-row>
        </v-card-title>
        <v-card-text class="pt-5">
            <v-row no-gutters>
                <v-col class="ma-0 pa-0">
                    <v-card hover rounded class="ma-0 pa-0">
                        <iframe v-if="type == 'link'" :src="url" style="width: 100%; height: 400px;" class="ma-0 pa-0"/>
                        <v-img v-else :src="url" v-on:click="closePopup" contain max-width="650" max-height="400"></v-img>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="">
            <v-row no-gutters>
                <v-col cols="12" class="pr-5 pb-3 pt-3 text-right block-bt">
                    <v-btn class="ma-2 white--text" width="130px" color="#4573D5" large v-on:click="closePopup()"><span style="font-size:1.4em;">확인</span></v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script>

export default {
    props: {
        url: String,
        type: String,
    },
    data: () => ({}),
    methods: {
        closePopup() {
            this.$emit('cancel')
        }
    }
}
</script>
[